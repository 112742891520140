<template>
  <div>
    <div @click="show()">
      <div class="badge badge-soft-info font-size-12" v-if="eq.status=='read'">
        Read
      </div>
      <div class="badge badge-soft-success font-size-12" v-else-if="eq.status=='replied'">
        Reply
      </div>
      <div class="badge badge-soft-danger font-size-12" v-else>
        New
      </div>
    </div>

    <b-modal size="lg" :id="`show-enquiry-reply${this.eq._id}`" title="Reply" hide-footer>
      <div class="row">
        <div class="col-12">
          From: <a :href="'mailto://'+eq.email">{{eq.email}}</a>
        </div>
        <div class="col-12 mb-2">
          Message: {{eq.message}}
        </div>
        <div class="col-12">

          <ckeditor v-model="editorData" :editor="editor"></ckeditor>
        </div>
        <div class="col-12 mt-2 text-right">
          <b-button pill variant="primary" @click="reply">
            Reply
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: {
    eq: {
      type: Object
    }
  },
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData:
        "MAWJ Platforms",
      formValues: {

      },
    };
  },
  methods: {
    show() {
      if (this.eq && this.eq.status == 'new') { this.readEq(); }
      this.$bvModal.show(`show-enquiry-reply${this.eq._id}`);
    },
    hide() {
      this.$bvModal.hide(`show-enquiry-reply${this.eq._id}`);
    },
    reply() {
      const api = {
        url: "enquiry/replyEnquiry",
        method: "POST",
        data: {
          reply: this.editorData,
          enquiry_id: this.eq._id
        },
      }
      this.generateAPI(api)
        .then(() => {
          this.eq.status = "replied"
          this.$bvModal.hide(`show-enquiry-reply${this.eq._id}`);
          this.$bvToast.toast("Replied successfully!", {
            title: "Replied",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log("Errr", err);
        })
    },
    readEq() {
      const api = {
        url: "enquiry/setToRead/",
        method: "PUT",
        id: this.eq._id
      }
      this.generateAPI(api).then(() => {
        this.eq.status = "read"
      }).catch((err) => {
        console.log("Errr", err);
      })

    },
  },
};
</script>
